<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.industryName"
              placeholder="行业名称"
              allowClear
          />
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <!--        <a-button style="margin-left: 8px" @click="reset">重置</a-button>-->
      </div>
      <a-button type="primary" @click="add" style="margin-left:20px;" icon="plus">
        新增
      </a-button>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="status" slot-scope="text, record">
        <a-tag :color="record.status | dictName('validStatus', 'color')" style="cursor: pointer">
          {{ record.status| dictName("validStatus") }}
        </a-tag>
      </template>
      <template slot="qrCode" slot-scope="text, record">
        <div v-if="record.qrCode" class="img-list">
          <el-image
              style="width: 40px; height: 40px"
              :src="(record.qrCode || '') | fullImgPath"
              fit="contain"
              :preview-src-list="(record.qrCode || '').split(',')"
          />
        </div>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-button type="primary" icon="edit" size="small" @click="modify(record)">
          修改
        </a-button>
      </template>
    </a-table>
    <modify-modal ref="modifyModal" @success="editSuccess"/>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";
import modifyModal from "./modifySysAdChannelModal";

export default {
  name: "SysIndustryList",
  mixins: [ListMixin],
  components: {modifyModal},
  data() {
    return {
      listGetUrl: "adChannel/list",
      listMethod: "get",
    };
  },
  computed: {
    textColor() {
      return this.$store.state.setting.color;
    },
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },

        {
          title: "行业名称",
          align: "center",
          width: 150,
          dataIndex: "channelName",
        },
        {
          title: "二维码图片",
          align: "center",
          width: 150,
          dataIndex: "qrCode",
          scopedSlots: {customRender: "qrCode"},
        },
        {
          title: "备注说明",
          align: "center",
          width: 200,
          dataIndex: "remark",
        },
        {
          title: "操作",
          width: 100,
          align: "center",
          dataIndex: "operation",
          scopedSlots: {customRender: "operation"},
        },
        {}
      ];
    },
  },

  mounted() {
    this.search();
  },
  methods: {
    editSuccess() {
      this.search();
    },
    add() {
      this.$refs.modifyModal.showModal(undefined, 'new');
    },
    modify(record) {
      this.$refs.modifyModal.showModal(record, 'update');
    },
  },
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
